<template>
  <h1>{{ type }} Associated References</h1>
</template>

<script>
export default {
  name: 'AssociatedReferences',
  data() {
    return {
      type: ''
    }
  },
  mounted() {
    this.type = this.$route.meta.expenses ? 'Expenses' : 'Income'
  }
}
</script>
